<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="股东会决议有效性管理"
    >
      <base-form
        :componentList="shareholderMeetingConfig"
        :formAttrs="{
          model: formData,
          labelWidth: '140px',
        }"
        :showBtns="false"
        class="formData"
        ref="ShareholderMeetingType"
      />
      <template slot="footer">
        <base-button label="提交" @click.prevent="submit" type="primary" />
        <base-button
          label="取消"
          @click="visibleState = false"
          type="default"
        />
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { resolutionAPi } from '@/api/businessApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { MeetingConfig } from '../utils/config'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
export default {
  components: {
    BaseDialog,
    BaseButton,
    BaseForm
  },
  props: {
    meetingConferenceShow: Boolean
  },
  data () {
    return {
      formData: {
        validTimeUnit: ''
      },
      startOptions: {
        disabledDate (time) {
          return time.getTime() <= new Date('1999-12-31')
        }
      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    shareholderMeetingConfig () {
      return MeetingConfig(this)
    },
    visibleState: {
      get () {
        if (this.meetingConferenceShow) {
          this.getShareholderResultValidInfo()
        }
        return this.meetingConferenceShow
      },
      set (val) {
        this.$emit('update:meetingConferenceShow', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 查询股东会决议有效性信息
    getShareholderResultValidInfo () {
      const params = {
        businessId: this.$route.query.businessId
      }
      resolutionAPi.getShareholderResultValidInfo(params).then((res) => {
        this.formData = res.data
      })
    },
    // 修改年月日
    changeValidTimeUnit (val) {
      this.$set(this.formData, 'validTimeUnit', val)
      this.verification()
    },
    // 校验数据完整
    verification () {
      let pass = false

      if (
        this.formData.resultValidTimeStart &&
        this.formData.validTimeNum &&
        this.formData.validTimeUnit
      ) {
        pass = true
      }

      // 验证通过调用自动计算计算结束时间
      if (pass) {
        const params = {
          resultValidTimeStart: this.formData.resultValidTimeStart,
          validTimeNum: this.formData.validTimeNum,
          validTimeUnit: this.formData.validTimeUnit
        }
        resolutionAPi.getResultValidTimeEnd(params).then((res) => {
          console.log(res.data, 'getResultValidTimeEnd')
          this.$set(this.formData, 'resultValidTimeEnd', res.data)
        })
      }
    },

    // 提交
    submit () {
      this.$refs.ShareholderMeetingType.validate((valid) => {
        console.log(valid)
        if (valid) {
          resolutionAPi.updateByEntity(this.formData).then((res) => {
            this.success('修改成功')
            this.visibleState = false
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.top {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.addbtn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
  padding-right: 20px;
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;

    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
